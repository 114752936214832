<template>
  <div class="page bg-white" id="reorganize">
    <div class="pageHeight">
      <div class="minTitle">入库详情</div>
      <el-form :model="inputForm" ref="inputForm" :rules="rules" label-width="200px"
               size="small">
        <el-row>
          <el-col :span="11">
            <el-form-item label="藏品名称" prop="collectionName">
              <el-input :disabled="inputType"
                        v-model.trim="inputForm.collectionName"
                        placeholder="请输入藏品名称(限300字)"
                        maxlength="300" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="入库原因" prop="reason">
              <el-select
                  :disabled="inputType"
                  v-model="inputForm.reason"
                  clearable
                  placeholder="请选择入库原因"
                  style="width: 100%"
              >
                <el-option
                    v-for="item in this.$dictUtils.getDictList('outbound_reason')"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="22">
            <el-form-item label="具体原因" prop="specificReasons">
              <el-input type="textarea" rows="1" resize="none" :disabled="inputType"
                        v-model.trim="inputForm.specificReasons" maxlength="50"
                        placeholder="请输入具体原因(限50字)"
                        clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="入库申请人" prop="applicantName">
              <el-input
                  v-model.trim="inputForm.applicantName"
                  placeholder="请输入入库申请人(限50字)"
                  maxlength="50"
                  :disabled="true"
                  clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="预入库时间" prop="preDate">
              <el-date-picker
                  v-model="inputForm.preDate"
                  :disabled="inputType"
                  type="date"
                  clearable
                  style="width: 100%"
                  value-format="yyyy-MM-dd"
                  placeholder="选择预入库时间">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="22">
            <el-form-item label="备注说明" prop="remark">
              <el-input type="textarea" rows="4" resize="none" :disabled="inputType"
                        v-model.trim="inputForm.remark" maxlength="5000"
                        placeholder="详细描述藏品出库的详细原因以及备注(限500字)"
                        clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="应完残程度" prop="shouldLevel">
              <el-input :disabled="inputType"
                        v-model.trim="inputForm.shouldLevel"
                        placeholder="请输入应完残程度(限50字)"
                        maxlength="50" clearable></el-input>
            </el-form-item>
          </el-col>
          <!--                    <el-col :span="11">-->
          <!--                        <el-form-item label="实完残程度" prop="actualLevel">-->
          <!--                            <el-input :disabled="inputType"-->
          <!--                                      v-model.trim="inputForm.actualLevel"-->
          <!--                                      placeholder="请输入应完残程度(限50字)"-->
          <!--                                      maxlength="50" clearable></el-input>-->
          <!--                        </el-form-item>-->
          <!--                    </el-col>-->
          <el-col :span="11">
            <el-form-item label="实完残程度" prop="actualLevel">
              <el-input :disabled="inputType"
                        v-model.trim="inputForm.actualLevel"
                        placeholder="请输入实完残程度(限50字)"
                        maxlength="50" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="入库状态" prop="storeState">
              <el-select
                  :disabled="inputType"
                  v-model="inputForm.storeState"
                  clearable
                  placeholder="请选择入库状态"
                  style="width: 100%"
              >
                <el-option
                    v-for="item in this.$dictUtils.getDictList('store_state')"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="申请状态" prop="applicationState">
              <el-select
                  v-model="inputForm.applicationState"
                  :disabled="inputType"
                  clearable
                  placeholder="请选择申请状态"
                  style="width: 100%"
              >
                <el-option
                    v-for="item in applicationStateList"
                    :key="item.state"
                    :label="item.stateName"
                    :value="item.state"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="入库/注销操作人" prop="operatorName">
              <el-input :disabled="inputType"
                        v-model.trim="inputForm.operatorName"
                        placeholder="请输入入库/注销操作人(限100字)"
                        maxlength="100" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="实入库/注销时间" prop="operatorTime">
              <el-date-picker
                  v-model="inputForm.operatorTime"
                  :disabled="inputType"
                  type="datetime"
                  clearable
                  style="width: 100%"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="选择实入库/注销时间">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="minTitle">审批记录</div>
      <el-table
          :data="dataList"
          size="small"
          v-loading="loading"
          ref="multipleTable"
          height="300px"
          class="table"
      >
        <el-table-column label="序号" type="index" width="50"></el-table-column>
        <el-table-column prop="processResult" label="审核结果" show-overflow-tooltip></el-table-column>
        <el-table-column prop="createBy" label="审核人" show-overflow-tooltip></el-table-column>
        <el-table-column prop="processName" label="审核操作" show-overflow-tooltip></el-table-column>
        <el-table-column prop="processOpinion" label="审核意见" show-overflow-tooltip></el-table-column>
        <el-table-column prop="createDate" label="审核时间" show-overflow-tooltip></el-table-column>
        <el-table-column prop="submitBy" label="提交人" show-overflow-tooltip></el-table-column>
        <el-table-column prop="submitTime" label="提交时间" show-overflow-tooltip></el-table-column>
      </el-table>
      <div class="text_center">
        <el-pagination
            @size-change="sizeChangeHandle"
            @current-change="currentChangeHandle"
            :current-page="pageNo"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageSize"
            :total="total"
            background
            layout="prev, pager, next"
        >
        </el-pagination>
      </div>
    </div>
    <div class="text_center" style="margin-top: 10px">
      <el-button size="small" type="primary" @click="switchData(1)">上一条</el-button>
      <el-button size="small" type="primary" @click="switchData(0)">下一条</el-button>
      <el-button size="small" @click="returnPage(1)">关闭</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "outboundDetails",
  data() {
    return {
      id: '',
      inputForm: {
        collectionName: '',
        reason: '',
        specificReasons: '',
        applicantName: '',
        preDate: '',
        remark: '',
        storeState: '',
        applicationState: '',
        operator: '',
        soperationDate: '',
        shouldLevel: '',
        actualLevel: '',
      },
      rules: {
        applicantName: [
          {required: true, message: '请输入出库申请人', trigger: 'blur'}
        ],
        specificReasons: [
          {required: true, message: '请输入出库申请人', trigger: 'blur'}
        ],
        preDate: [
          {required: true, message: '请选择预入库时间', trigger: 'change'}
        ],

      },
      inputType: true,
      storeStateList: [],
      applicationStateList: [],

      dataList: [],
      loading: false,
      pageNo: 1,
      pageSize: 10,
      total: 0,
      registrationSearch: {},
    }
  },

  mounted() {
    this.id = this.$route.query.id
    this.switchData('', 1)
    this.getAllState()
    this.$axios(this.api.collection.listItemOrderApplication, {}, 'get').then(data => {
      if (data.status) {
        this.applicationStateList = data.data
      }
    })
  },

  methods: {
    getrecordList() {
      this.loading = true
      this.$axios(this.api.collection.collectionprocessinfoList, {
        collectionDataId: this.id,
        current: this.pageNo,
        size: this.pageSize,
      }, "post").then((data) => {
        if (data && data.status) {
          this.dataList = data.data.collectionProcessInfoVOIPage.records;
          this.total = parseInt(data.data.collectionProcessInfoVOIPage.total);
          this.loading = false;
        }
      });
    },

    // 展览每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageNo = 1;
      this.getrecordList();
    },
    // 展览当前页
    currentChangeHandle(val) {
      this.pageNo = val;
      this.getrecordList();
    },

    //1为上一条 0为下一条
    switchData(num, type) {
      if (Object.keys(this.registrationSearch).length == 0) {
        this.registrationSearch = JSON.parse(sessionStorage.getItem('registrationSearch'))
      }
      if (!type) {
        if (num == 0) {
          this.registrationSearch.current++
        } else {
          if (this.registrationSearch.current == 1) {
            this.$message.error('已是第一条')
          } else {
            this.registrationSearch.current--
          }
        }
      }

      let fetchUrl = this.api.collection.standingBook
      let methodsType = 'post'
      this.$axios(fetchUrl, this.registrationSearch, methodsType).then((res) => {
        if (res.status) {
          if (res.data && res.data.records.length) {
            this.id = res.data.records[0].orderId
            this.inputForm = res.data.records[0]
            this.inputForm.reason = this.inputForm.reason + ''
            this.inputForm.storeState = this.inputForm.storeState + ''
            this.pageNo = 1
            this.getrecordList()
          } else {
            this.registrationSearch.current--
            this.$message.error(`已是${num == 1 ? '第一' : '最后'}一条`);
          }
        } else {
          this.$message.error(res.msg);
        }
      })
    },

    //返回
    returnPage(num) {
      this.$router.back()
    },

    getAllState() {
      this.$axios(this.api.collection.listLevelSelect).then(data => {
        if (data.status) {
          this.leaveList = data.data
        }
      })
      this.$axios(this.api.collection.listSelect).then(data => {
        if (data.status) {
          this.typeList = data.data
        }
      })
      this.$axios(this.api.collection.listOutBount, {}, 'get').then(data => {
        if (data.status) {
          this.storeStateList = data.data
        }
      })
    },
  },
}
</script>

<style scoped>
.bg-white {
  overflow-y: auto !important;
}

.minTitle {
  font-weight: bold;
  font-size: 14px;
  margin-top: 20px;
}

.pageHeight {
  height: calc(100vh - 205px);
  overflow-y: auto;
}
</style>